import { useState, useMemo, useCallback } from "react"

export type LoadableIsLoading = boolean
export type LoadableError = Error
export type LoadableBundle<V> = [LoadableIsLoading, LoadableError | undefined, V | undefined]

type SetValueCallback<V> = (currentValue?: V) => V

export interface LoadableApi<V> {
  setIsLoading: (loading: boolean) => void,
  setError: (error: LoadableError | undefined) => void,
  setValue: (value: V) => void,
  setValueCallback: (nextValueCb: SetValueCallback<V>) => void,
  setLoadedValueWithoutError: (value: V) => void
  setLoadedValueWithoutErrorCallback: (nextValueCb: SetValueCallback<V>) => void
}

export const dummyLoadableApi = {
  setError: () => null,
  setIsLoading: () => null,
  setValue: () => null,
  setValueCallback: () => null,
  setLoadedValueWithoutError: () => null,
  setLoadedValueWithoutErrorCallback: () => null,
}

export const emptyLoadableBundleFactory = <T>() => [false, undefined, undefined] as LoadableBundle<T>

type IUseLoadableState<V> = [
  LoadableBundle<V>,
  LoadableApi<V>
]

export default function useLoadableState<V>(initialState?: V): IUseLoadableState<V> {
  // const [isLoading, setIsLoading] = useState<LoadableIsLoading>(!!initialState)
  // const [error, setError] = useState<LoadableError>()
  // const [value, setValue] = useState<V | undefined>(initialState)
  const [state, setState] = useState<LoadableBundle<V>>(() => [!!initialState, undefined, initialState])


  const setIsLoading = useCallback((nextIsLoading) => {
    setState(([, error, value]) => [nextIsLoading, error, value])
  }, [setState])

  const setError = useCallback((nextError) => {
    setState(([isLoading, , value]) => [isLoading, nextError, value])
  }, [setState])

  const setValue = useCallback((nextValue) => {
    setState(([isLoading, error,]) => [isLoading, error, nextValue])
  }, [setState])

  const setValueCallback = useCallback((nextValueCb: (v?: V) => V) => {
    setState(([isLoading, error, currentValue]) => [isLoading, error, nextValueCb(currentValue)])
  }, [setState])

  const setLoadedValueWithoutError = useCallback((nextValue: V) => {
    setState([false, undefined, nextValue])
  }, [setState])

  const setLoadedValueWithoutErrorCallback = useCallback((nextValueCb: (v?: V) => V) => {
    setState(([, , currentValue]) => [false, undefined, nextValueCb(currentValue)])
  }, [setState])

  return [
    state,
    {
      setIsLoading,
      setValue,
      setValueCallback,
      setError,
      setLoadedValueWithoutError,
      setLoadedValueWithoutErrorCallback,
    }
  ]
}