import { useState, useCallback } from "react";
import * as yup from 'yup'
import { requiredField, emailSchemaFactory, mobileSchemaFactory, stringSchemaFactory } from "../forms/schemas";
import { CourseProduct } from "../lib/coursesApi/CoursesApiClient";
import { validate } from "../utils/schemaValidation";

export interface CourseProductOrderRowParticipant {
  name: string,
  mobile: string,
}

export const emptyCourseProductOrderRowParticipant = {
  name: "",
  mobile: "",
}

export function isEmptyCourseProductOrderRowParticipant(participant: CourseProductOrderRowParticipant): boolean {
  return (
    participant.mobile === "" &&
    participant.name === ""
  )
}

export interface CourseProductOrderRow {
  productId: CourseProduct['id'],
  product: CourseProduct,
  adminEmail: string,
  participants: CourseProductOrderRowParticipant[],
  amount: number
}

export const orderRowSchema = yup.object({
  product: yup.object().defined(),
  productId: requiredField(yup.number().integer()),
  adminEmail: emailSchemaFactory(true),
  participants: yup.array().of(
    yup.object({
      mobile: mobileSchemaFactory(true),
      name: stringSchemaFactory(true, 4),
    })
      .defined()
  )
    .ensure()
    .defined(),
  amount: requiredField(
    requiredField(
      yup.number().integer("Endast heltal")
    )
      .positive("Större än 0")
      .default(1)
  )
}).defined()

interface IUseOrderRowApi {
  setAdminEmail: (nextAdminEmail: string) => void,
  setAmount: (nextAmount: number) => void,
  setParticipants: (nextParticipantsFn: (nextParticipants: CourseProductOrderRowParticipant[]) => CourseProductOrderRowParticipant[]) => void,
  setParticipantFieldValue: (participantIndex: number, fieldName: string, nextFieldValue: string) => void,
  reset: () => void,
  triggerValidation: () => void,
}

type IUseOrderRow = [yup.ValidationError[], CourseProductOrderRow, IUseOrderRowApi]


export default function useOrderRow(initialOrderRow: CourseProductOrderRow): IUseOrderRow {
  const [state, setState] = useState<CourseProductOrderRow>(initialOrderRow)
  const [validationErrors, setValidationErrors] = useState<yup.ValidationError[]>([])

  const triggerValidation = useCallback(() => {
    const [nextValidationErrors, nextState] = validate<CourseProductOrderRow>(
      orderRowSchema as any as yup.SchemaOf<CourseProductOrderRow>,
      state
    )
    setValidationErrors(nextValidationErrors)
    setState(() => nextState)
  }, [setState, state])

  const setAdminEmail = useCallback((nextAdminEmail: string) => {
    setState((currentState) => ({
      ...currentState,
      adminEmail: nextAdminEmail
    }))
  }, [setState])

  const setAmount = useCallback((nextAmount: number) => {
    setState((currentState) => ({
      ...currentState,
      amount: nextAmount
    }))
  }, [setState])

  const setParticipants = useCallback((nextParticipantsFn: (nextParticipants: CourseProductOrderRowParticipant[]) => CourseProductOrderRowParticipant[]) => {
    setState((currentState) => {
      const nextParticipants = nextParticipantsFn(currentState.participants)
        .filter((participant) => !isEmptyCourseProductOrderRowParticipant(participant))

      return {
        ...currentState,
        participants: nextParticipants,
        amount: Math.max(nextParticipants.length, 1)
      }
    })
  }, [setState])

  const setParticipantFieldValue = useCallback((participantIndex: number, fieldName: string, nextFieldValue: string) => {
    setParticipants((currentParticipations) => {
      const nextParticipants = (function () {
        if (participantIndex <= currentParticipations.length - 1) {
          return currentParticipations.map((participant, _participantIndex) => (
            participantIndex === _participantIndex ? {
              ...participant,
              [fieldName]: nextFieldValue
            } : participant
          ))
        }
        else {
          return [
            ...currentParticipations,
            {
              ...emptyCourseProductOrderRowParticipant,
              [fieldName]: nextFieldValue
            }
          ]
        }
      })()

      return nextParticipants
    })
  }, [setParticipants])

  const reset = useCallback(() => {
    setState(() => initialOrderRow)
  }, [setState, initialOrderRow])

  return [
    validationErrors,
    state,
    {
      setAdminEmail,
      setAmount,
      setParticipants,
      setParticipantFieldValue,
      reset,
      triggerValidation
    }
  ]
}