import { RequestPath, requestPathToString } from '../utils/path'
import { Company } from '../../state/useOrder'
import { getFullUrl } from '../../utils/urls'

interface RequestPayload {
  [key: string]: any
}

interface ResponsePayload {
  [key: string]: any
}

export type ID = number

export enum CourseNodeType {
  Course = 'course',
  CourseGroup = 'coursegroup'
}

export interface CourseNode {
  id: ID,
  node_type: CourseNodeType,
  name: string,
  slug: string,
  description: string,
  description_long: string,
  parent_node: number,
  object_id: ID,
  icon_image?: Image
}

export interface CourseNodeDetail extends CourseNode {
  children: CourseNode[]
}

export interface TicketRecipe {
  id: ID,
  period_num_minutes: number,
  course: CourseSummary
}

export interface CourseProduct {
  id: ID,
  name: string,
  description: string,
  icon_image: Image | null,
  unit_price: number,
  public_unit_price_bundle: [number, string | null],
  ticket_recipes: TicketRecipe[]
}

export interface Video {
  id: ID,
  name: string,
  file: string,
  poster?: string | null
}

export interface Image {
  id: ID,
  name: string,
  file: string
}

export interface Document {
  id: ID,
  name: string,
  file: string
}


export interface CourseGroup {
  id: ID,
  name: string,
  slug: string,
  description: string,
  description_long: string,
  image: null | Image
}

export interface QuizRuleset {
  id: ID,
  name: string,
  min_percentage_for_passing: null | number,
  time_limit_in_minutes: null | number,
  num_questions: number,
}
export interface QuizRulesetWithSections {
  id: ID,
  name: string,
  min_percentage_for_passing: null | number,
  time_limit_in_minutes: null | number
}

export interface LessonSummary {
  id: ID,
  name: string,
  slug: string,
  icon_image: Image
}

export enum LessonBlockType {
  Video = 'video',
  Text = 'text',
  Quiz = 'quiz',
  QuizWithSections = 'quiz-with-sections',
}

export interface LessonBlock {
  id: number,
  block_type: LessonBlockType,
  is_passage: boolean,
  name: string,
}

export interface VideoLessonBlock extends LessonBlock {
  video: Video
}

export interface TextLessonBlock extends LessonBlock {
  content: string,
}

export interface QuizLessonBlock extends LessonBlock {
  content: string,
  quiz_ruleset: QuizRuleset
}
export interface QuizWithSectionsLessonBlock extends LessonBlock {
  content: string,
  quiz_ruleset: QuizRulesetWithSections
}

export interface Lesson extends LessonSummary {
  blocks: LessonBlock[],
}

export interface CourseSummary {
  id: ID,
  name: string,
  slug: string,
  icon_image?: null | Image,
  banner_image?: null | Image,
  background_image?: null | Image,
}

export interface Course {
  id: ID,
  name: string,
  description: string,
  description_long: string,
  slug: string,
  lessons: LessonSummary[],
  about_long?: string,
  about_short?: string,
  trailer_video?: Video,
  icon_image?: null | Image,
  banner_image?: null | Image,
  background_image?: null | Image,
  documents: Document[],
  group?: CourseGroup,
  certification_quiz_ruleset?: null | QuizRuleset
}

export interface CourseParticipationLessonBundle {
  id: ID,
  lesson_id: ID,
  has_been_viewed: boolean,
  is_last_viewed: boolean,
  is_unlocked: boolean,
  lesson: Lesson,
  unlocks_when_passes_lessons: number[],
}

interface CertificationQuizSubmit {
  num_questions: number
  num_correct_answered_questions: number
  started_at: string,
  submitted_at: string,
  time_limit_in_minutes: number
  min_percentage_for_passing: number
  percentage_correct_answered_questions: number
  time_in_minutes: number
  has_passed: boolean
}

export enum CertificationIssuing {
  Id06 = "id06",
  Email = "email"
}

interface RawCourseParticipation {
  id: ID,
  course: Course,
  cancelled_at: string | null,
  certified_at: string | null,
  expires_at: string | null,
  course_lessons: CourseParticipationLessonBundle[],
  has_expired: boolean,
  is_cancelled: boolean,
  is_valid: boolean,
  last_viewed_course_lesson: ID | null,
  viewed_course_lessons: ID[],
  passed_course_lessons: ID[],
  passed_lesson_blocks: ID[],
  user_id: string,
  certification_quiz_submits?: CertificationQuizSubmit[],
  certification_issuing: CertificationIssuing[],
  diploma_url: string
}

export interface CourseParticipation extends RawCourseParticipation {
  hasBeenUsed: boolean
}

export function courseParticipationHasBeenUsed(rawCourseParticipation: RawCourseParticipation): boolean {
  return rawCourseParticipation.viewed_course_lessons.length > 0
}

function importRawCourseParticipation(rawCourseParticipation: RawCourseParticipation): CourseParticipation {
  return {
    ...rawCourseParticipation,
    hasBeenUsed: courseParticipationHasBeenUsed(rawCourseParticipation)
  }
}

export type SelectedQuizAnswers = (ID[])[]

export interface QuizQuestionAnswer {
  id: ID,
  text: string,
  is_correct?: boolean
}

export interface QuizQuestion {
  id: ID,
  content: string,
  answers: QuizQuestionAnswer[]
}

export interface Quiz {
  min_percentage_for_passing: null | number,
  time_limit_in_minutes: null | number,
  minimum_num_of_answers: number,
  maximum_num_of_answers: number,
  minimum_num_of_correct_answers: number,
  maximum_num_of_correct_answers: number,
  questions: QuizQuestion[],
}

export interface QuizBundle {
  submit_token_payload: object,
  submit_token: string,
  quiz: Quiz,
  temporary_selected_answers: SelectedQuizAnswers
}

export interface QuizQuestionResult {
  answer_selection_is_correct: boolean,
  answers: QuizQuestionAnswer[],
  correct_answer_ids: ID[]
  question: {
    id: ID,
    content: string
  },
  selected_answer_ids: ID[]
}

export interface QuizSection {
  id: ID,
  title: string,
  question_indexes: number[]
}

export interface QuizSubmitResultBundle {
  has_passed: boolean,
  min_percentage_for_passing: null | number,
  time_limit_in_minutes: null | number,
  num_questions: number,
  time_in_minutes: number,
  num_correct_answered_questions: number,
  percentage_correct_answered_questions: number,
  result: QuizQuestionResult[],
  sections: QuizSection[],
}

interface InvoiceSettings {
  email: string,
  reference?: string,
  address: string,
  postalCode: string,
  city: string,
}

interface CourseProductOrderRowParticipant {
  name: string,
  mobile: string,
}


interface CourseProductOrderRow {
  productId: CourseProduct['id'],
  adminEmail: string,
  participants: CourseProductOrderRowParticipant[],
  amount: number
}

export interface UserSettings {
  has_given_written_id06_consent?: boolean,
  has_approved_user_conditions?: boolean
}

export enum CompanyCourseParticipationTicketBundleStatus {
  Pending = "pending",
  Activated = "activated",
  InProgress = "in-progress",
  Certified = "certified",
  Expired = "expired",
  Invalid = "invalid",
}

export interface CompanyCourseParticipationTicketBundle {
  id: number,
  uuid: string,
  company_id: number,
  company: {
    id: number,
    name: string,
    registration_number: string,
  },
  token: string,
  key_code: string,
  source: string,
  course_name: string,
  created_at: string,
  diploma_url: string,
  status: CompanyCourseParticipationTicketBundleStatus,
  admin_user_full_names: string[],
  recipient_name?: string | null,
  recipient_email?: string | null,
  recipient_mobile?: string | null,
  user_full_name?: string | null,
  certified_at?: string,
  expires_at?: string,
}

export interface CompanyCourseParticipationTicketBundleAdministrationEmail {
  email: string,
  company: Company,
  num_of_company_course_participation_ticket_bundles: number,
}

export interface CompanyCourseParticipationTicketBundleAdministrationSummary {
  num_of_company_course_participation_ticket_bundles: number
}


export class CoursesApiRequestError extends Error {
  payload: any;

  constructor(payload: any) {
    super(payload.toString());

    this.payload = payload

    Object.setPrototypeOf(this, CoursesApiRequestError.prototype);
  }
}


interface DeliveryAddress {
  name: string,
  street: string,
  postalCode: string,
  city: string
}
export class CoursesApiClient {

  getUrl() {
    throw Error("Not implemented")
  }


  getHeaders() {
    return {}
  }


  async request(requestPath: RequestPath, requestInit: RequestInit): Promise<Response> {
    const fullUrl = `${this.getUrl()}${requestPathToString(requestPath)}`

    const mergedRequestInit: RequestInit = {
      ...requestInit,
      headers: {
        ...requestInit.headers,
        ...this.getHeaders()
      }
    }

    return fetch(fullUrl, mergedRequestInit)
  }

  async get(requestPath: RequestPath) {
    return this.request(requestPath, { method: "GET" })
  }

  async postJson<Q extends RequestPayload>(requestPath: RequestPath, payload: Q) {
    return this.request(requestPath, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
  }

  async patchJson<Q extends RequestPayload>(requestPath: RequestPath, payload: Q) {
    return this.request(requestPath, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
  }

  async delete(requestPath: RequestPath) {
    return this.request(requestPath, {
      method: 'DELETE',
    })
  }

  async getCourseNodes(haveNoParent: boolean = true): Promise<CourseNode[]> {
    try {
      const response = await this.get(`/courses/nodes?${haveNoParent ? "have_no_parent" : ""}`)
      if (response.ok) {
        try {
          const payload = (await response.json()) as CourseNode[]
          return payload
        } catch (error) {
          throw error
        }
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async getCourseNode(courseNodeSlug: string): Promise<CourseNodeDetail> {
    try {
      const response = await this.get(`/courses/nodes/${courseNodeSlug}`)
      if (response.ok) {
        try {
          const payload = (await response.json()) as CourseNodeDetail
          return payload
        } catch (error) {
          throw error
        }
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async getCourse(courseSlug: string): Promise<Course> {
    try {
      const response = await this.get(`/courses/${courseSlug}`)
      if (response.ok) {
        try {
          const payload = (await response.json()) as Course
          return payload
        } catch (error) {
          throw error
        }
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async getCourseProduct(courseProductId: string): Promise<CourseProduct> {
    try {
      const response = await this.get(`/products/${courseProductId}`)
      if (response.ok) {
        try {
          const payload = (await response.json()) as CourseProduct
          return payload
        } catch (error) {
          throw error
        }
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async getCourseProducts(): Promise<CourseProduct[]> {
    try {
      const response = await this.get(`/products`)
      if (response.ok) {
        try {
          const payload = (await response.json()) as CourseProduct[]
          return payload
        } catch (error) {
          throw error
        }
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async getUserCourseParticipations(): Promise<CourseParticipation[]> {
    try {
      const response = await this.get(`/course-participations`)
      if (response.ok) {
        try {
          const payload = ((await response.json()) as RawCourseParticipation[]).map(importRawCourseParticipation)
          return payload
        } catch (error) {
          throw error
        }
      }
      else {
        const errorPayload = await response.json()
        throw new Error(`Error: ${response.status} - ${response.statusText} (${JSON.stringify(errorPayload)})`)
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async getUserCourseParticipation(courseParticipationId: ID): Promise<CourseParticipation> {
    try {
      const response = await this.get(`/course-participations/${courseParticipationId}`)
      if (response.ok) {
        try {
          const payload = importRawCourseParticipation((await response.json()) as RawCourseParticipation)
          return payload
        } catch (error) {
          throw error
        }
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async getUserCourseParticipationLesson(courseParticipationId: ID, lessonId: ID): Promise<Lesson> {
    try {
      const response = await this.get(`/course-participations/${courseParticipationId}/lessons/${lessonId}`)
      if (response.ok) {
        try {
          const payload = (await response.json()) as Lesson
          return payload
        } catch (error) {
          throw error
        }
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }


  async addViewedCourseParticipationLesson(courseParticipationId: ID, lessonId: ID): Promise<CourseParticipation> {
    try {
      const response = await this.patchJson(`/course-participations/${courseParticipationId}/add-viewed-lesson`, {
        lesson_id: lessonId
      })

      if (response.ok) {
        try {
          const payload = importRawCourseParticipation((await response.json()) as RawCourseParticipation)
          return payload
        } catch (error) {
          throw error
        }
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async passUserCourseParticipationLessonBlock(courseParticipationId: ID, lessonId: ID, lessonBlockId: ID): Promise<CourseParticipation> {
    try {
      const response = await this.postJson<{}>(
        `/course-participations/${courseParticipationId}/lessons/${lessonId}/blocks/${lessonBlockId}/pass`,
        {}
      )

      if (response.ok) {
        try {
          const payload = importRawCourseParticipation((await response.json()) as RawCourseParticipation)
          return payload
        } catch (error) {
          throw error
        }
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async generateUserCourseParticipationLessonBlockQuiz(courseParticipationId: ID, lessonId: ID, lessonBlockId: ID): Promise<QuizBundle> {
    try {
      const response = await this.postJson<{}>(
        `/course-participations/${courseParticipationId}/lessons/${lessonId}/blocks/${lessonBlockId}/generate-quiz`,
        {}
      )

      if (response.ok) {
        try {
          const payload = (await response.json()) as QuizBundle
          return payload
        } catch (error) {
          throw error
        }
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async submitUserCourseParticipationLessonBlockQuiz(courseParticipationId: ID, lessonId: ID, lessonBlockId: ID, submitToken: string, selectedAnswers: SelectedQuizAnswers): Promise<[QuizSubmitResultBundle, CourseParticipation]> {
    try {
      const response = await this.postJson<{}>(
        `/course-participations/${courseParticipationId}/lessons/${lessonId}/blocks/${lessonBlockId}/submit-quiz`,
        {
          submit_token: submitToken,
          selected_answers: selectedAnswers
        }
      )
      if (response.ok) {
        try {
          const [quizSubmitResultBundle, rawCourseParticipation] = (await response.json()) as [QuizSubmitResultBundle, RawCourseParticipation]
          return [
            quizSubmitResultBundle,
            importRawCourseParticipation(rawCourseParticipation)
          ]
        } catch (error) {
          throw error
        }
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async generateUserCourseParticipationCertificationQuiz(courseParticipationId: ID): Promise<QuizBundle> {
    try {
      const response = await this.postJson<{}>(
        `/course-participations/${courseParticipationId}/certification/generate-quiz`,
        {}
      )

      if (response.ok) {
        try {
          const payload = (await response.json()) as QuizBundle
          return payload
        } catch (error) {
          throw error
        }
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async submitUserCourseParticipationCertificationQuiz(courseParticipationId: ID, submitToken: string, selectedAnswers: SelectedQuizAnswers): Promise<[QuizSubmitResultBundle, CourseParticipation]> {
    try {
      const response = await this.postJson<{}>(
        `/course-participations/${courseParticipationId}/certification/submit-quiz`,
        {
          submit_token: submitToken,
          selected_answers: selectedAnswers
        }
      )
      if (response.ok) {
        try {
          const [quizSubmitResultBundle, rawCourseParticipation] = (await response.json()) as [QuizSubmitResultBundle, RawCourseParticipation]
          return [
            quizSubmitResultBundle,
            importRawCourseParticipation(rawCourseParticipation)
          ]
        } catch (error) {
          throw error
        }
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }


  async getCompanyCourseParticipationTicketBundleTokenByCode(code: string) {
    try {
      const response = await this.postJson<{}>(
        `/course-participations/get-company-course-participation-ticket-bundle-token-by-code`,
        { code }
      )
      if (response.ok) {
        try {
          const responsePayload = (await response.json())
          return responsePayload
        } catch (error) {
          throw error
        }
      }
      else {
        const responseErrorPayload = (await response.json())
        throw new CoursesApiRequestError(responseErrorPayload)
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async useCompanyCourseParticipationTicketBundle(token: string) {
    try {
      const response = await this.postJson<{}>(
        `/course-participations/use-company-course-participation-ticket-bundle`,
        { token }
      )

      if (response.ok) {
        try {
          const responsePayload = (await response.json())
          return responsePayload
        } catch (error) {
          throw error
        }
      }
      else {
        const responseErrorPayload = (await response.json())
        throw new CoursesApiRequestError(responseErrorPayload)
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async submitCourseParticipationOrder(
    company: Company,
    invoiceSettings: InvoiceSettings,
    rows: CourseProductOrderRow[]
  ) {
    const requestPayload = {
      "company": {
        "registration_number": company.registrationNumber,
        "name": company.name
      },
      "invoice_settings": {
        "method": "invoice",
        "email": invoiceSettings.email,
        "reference": invoiceSettings.reference,
        "address": invoiceSettings.address,
        "postal_code": invoiceSettings.postalCode,
        "city": invoiceSettings.city
      },
      "rows": rows.map((row) => ({
        "product": row.productId,
        "quantity": row.amount,
        "recipients": row.participants.map((participant) => ({
          "name": participant.name,
          "mobile": participant.mobile,
        })),
        "admin_email": row.adminEmail,
      })),
      "company_course_participation_ticket_bundle_administration_email_link_template": getFullUrl(
        `?claimCompanyCourseParticipationTicketBundleAdministrationEmailToken={}`
      )
    }

    try {
      const response = await this.postJson<{}>(
        `/orders/submit`,
        requestPayload
      )
      if (response.ok) {
        try {
          const responsePayload = (await response.json())
          return responsePayload
        } catch (error) {
          throw error
        }
      }
      else {
        const responseErrorPayload = (await response.json())
        throw new CoursesApiRequestError(responseErrorPayload)
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")

  }

  async getUserSettings(): Promise<UserSettings> {
    try {
      const response = await this.get("/user-settings")
      if (response.ok) {
        try {
          const responsePayload = (await response.json())
          return responsePayload
        } catch (error) {
          throw error
        }
      }
      else {
        const responseErrorPayload = (await response.json())
        throw new CoursesApiRequestError(responseErrorPayload)
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async setUserSettings(userSettings: UserSettings): Promise<UserSettings> {
    try {
      const response = await this.patchJson<UserSettings>("/user-settings", {
        ...userSettings
      })

      if (response.ok) {
        try {
          const responsePayload = (await response.json())
          return responsePayload
        } catch (error) {
          throw error
        }
      }
      else {
        const responseErrorPayload = (await response.json())
        throw new CoursesApiRequestError(responseErrorPayload)
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async getCompanyCourseParticipationTicketBundlesSummary(): Promise<CompanyCourseParticipationTicketBundleAdministrationSummary> {
    try {
      const response = await this.get(`/company-course-participation-ticket-bundles/summary`)

      if (response.ok) {
        try {
          const responsePayload = (await response.json()) as CompanyCourseParticipationTicketBundleAdministrationSummary
          return responsePayload
        } catch (error) {
          throw error
        }
      }
      else {
        const responseErrorPayload = (await response.json())
        throw new CoursesApiRequestError(responseErrorPayload)
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async getCompanyCourseParticipationTicketBundles(): Promise<CompanyCourseParticipationTicketBundle[]> {
    try {
      const response = await this.get(`/company-course-participation-ticket-bundles`)

      if (response.ok) {
        try {
          const responsePayload = (await response.json()) as CompanyCourseParticipationTicketBundle[]
          return responsePayload
        } catch (error) {
          throw error
        }
      }
      else {
        const responseErrorPayload = (await response.json())
        throw new CoursesApiRequestError(responseErrorPayload)
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async resendMessageToCompanyCourseParticipationTicketBundleReceiver(companyCourseParticipationTicketBundleUuids: string[]) {
    try {
      const response = await this.patchJson<{}>({
        path: `/company-course-participation-ticket-bundles/resend-retriever-message`,
        query: {
          course_participation_ticket_bundle_uuids: companyCourseParticipationTicketBundleUuids
        }
      }, {})

      if (response.ok) {
        try {
          const responsePayload = (await response.json())
          return responsePayload
        } catch (error) {
          throw error
        }
      }
      else {
        const responseErrorPayload = (await response.json())
        throw new CoursesApiRequestError(responseErrorPayload)
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async sendReminderToCompanyCourseParticipationTicketBundleParticipants(companyCourseParticipationTicketBundleUuids: string[]) {
    try {
      const response = await this.patchJson<{}>({
        path: `/company-course-participation-ticket-bundles/send-reminder-message`,
        query: {
          course_participation_ticket_bundle_uuids: companyCourseParticipationTicketBundleUuids
        }
      }, {})

      if (response.ok) {
        try {
          const responsePayload = (await response.json())
          return responsePayload
        } catch (error) {
          throw error
        }
      }
      else {
        const responseErrorPayload = (await response.json())
        throw new CoursesApiRequestError(responseErrorPayload)
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }


  async claimCompanyCourseParticipationTicketBundleAdministrationEmail(token: string): Promise<CompanyCourseParticipationTicketBundleAdministrationEmail> {
    try {
      const response = await this.postJson(`/companies/claim-course-participation-ticket-bundle-administration-email/${token}`, {})

      if (response.ok) {
        try {
          const responsePayload = (await response.json() as CompanyCourseParticipationTicketBundleAdministrationEmail)
          return responsePayload
        } catch (error) {
          throw error
        }
      }
      else {
        const responseErrorPayload = (await response.json())
        throw new CoursesApiRequestError(responseErrorPayload)
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async createClaimCourseParticipationTicketBundleAdministrationEmailTokenOfPreviousUserToken(previousUserToken: string): Promise<string> {
    try {
      const response = await this.get(`/companies/create-claim-course-participation-ticket-bundle-administration-email-token-of-previous-user-token/${previousUserToken}`)

      if (response.ok) {
        try {
          return (await response.json()).token
        } catch (error) {
          throw error
        }
      }
      else {
        const responseErrorPayload = (await response.json())
        throw new CoursesApiRequestError(responseErrorPayload)
      }
    } catch (error) {
      throw error
    }

    throw new Error("Huh?")
  }

  async orderUsersCompletedCourseParticipations(courseParticipationIds: number[], deliveryAddress: DeliveryAddress): Promise<true> {
    try {
      const response = await this.postJson("/misc/order-completed-course-participation-card", {
        name: deliveryAddress.name,
        street: deliveryAddress.street,
        postal_code: deliveryAddress.postalCode,
        city: deliveryAddress.city,
        course_participations: courseParticipationIds,
      })

      if (response.ok) {
        return true
      }
      else {
        const responseErrorPayload = (await response.json())
        throw new CoursesApiRequestError(responseErrorPayload)
      }
    } catch (error) {
      throw error
    }
  }

  async deleteUser(): Promise<true> {
    try {
      const response = await this.delete("/users/delete-user")
      if (response.ok) {
        return true
      }
      else {
        throw new CoursesApiRequestError(
          await response.json()
        )
      }
    } catch (error) {
      throw error
    }
  }

  async mergeUser(slaveUserToken: string): Promise<true> {
    try {
      const response = await this.patchJson("/users/merge", {
        slave_user_token: slaveUserToken,
      })
      if (response.ok) {
        return true
      }
      else {
        throw new CoursesApiRequestError(
          await response.json()
        )
      }
    } catch (error) {
      throw error
    }
  }
}

class ClientSideCoursesApiClient extends CoursesApiClient {
  getUrl() {
    return "/api/courses"
  }
}

export function clientSideCoursesApiClientFactory(): CoursesApiClient {
  return new ClientSideCoursesApiClient()
}

class ServerSideCoursesApiClient extends CoursesApiClient {
  accessToken?: string

  constructor(accessToken?: string) {
    super()
    this.accessToken = accessToken
  }

  getUrl() {
    return process.env.COURSES_API_URL
  }

  getHeaders() {
    return {
      ...(this.accessToken ? {
        "Authorization": `Bearer ${this.accessToken}`
      } : {})
    }
  }
}

export async function serverSideCoursesApiClientFactory(token?: string): Promise<CoursesApiClient> {
  return new ServerSideCoursesApiClient(token)
}

class BuildTimeCoursesApiClient extends CoursesApiClient {
  getUrl() {
    return process.env.BUILD_TIME_COURSES_API_URL
  }
}

export async function buildTimeCoursesApiClientFactory(): Promise<CoursesApiClient> {
  return new BuildTimeCoursesApiClient()
}

