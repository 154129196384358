import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Script from 'next/script'

import { UserProvider } from '../state/User'
import { OrderContextProvider } from '../state/useOrder'

import DefaultMDXProvider from '../components/DefaultMDXProvider'
const AppHandlers = dynamic(() => import('../containers/AppHandlers'), { ssr: false })

import 'antd/dist/antd.css'
import '../styles/index.css'
import '../styles/app.css'
interface ExtendedAppProps extends AppProps {
  err: any
}

export default function App({ Component, pageProps, err }: ExtendedAppProps) {
  return (
    <>
      {/* <Script
        strategy="beforeInteractive"
        src="https://cdn-cookieyes.com/client_data/286b5498d2eadac2650cadba/script.js"
      /> */}
      <UserProvider>
        <OrderContextProvider>
          <DefaultMDXProvider>
            <>
              <Component {...pageProps} err={err} />
              <AppHandlers />
            </>
          </DefaultMDXProvider>
        </OrderContextProvider>
      </UserProvider>
    </>
  )
}