import { Button } from 'antd'
import Link, { LinkProps as NextLinkProps } from 'next/link'
import { forwardRef, PropsWithChildren } from 'react'
import { AnyChildren } from '../types'
import { ButtonProps } from 'antd/lib/button'

interface InnerLinkProps extends ButtonProps {
  children: AnyChildren,
}

const InnerLink = forwardRef<HTMLElement, InnerLinkProps>(function InnerLink({ children, ...props }, ref) {
  return (
    <Button ref={ref} {...props}>
      {children}
    </Button>
  )
})

export interface LinkButtonProps extends PropsWithChildren<NextLinkProps> {
  buttonProps?: ButtonProps,
  native?: boolean
}

export default function LinkButton({ href, children, buttonProps, native }: LinkButtonProps) {
  if (native) {
    return <a href={href as string}>
      <InnerLink {...buttonProps}>{children}</InnerLink>
    </a>
  }
  else {

    return (
      <Link
        href={href}
        shallow
      >
        <a>
          <InnerLink {...buttonProps}>{children}</InnerLink>
        </a>
      </Link>
    )
  }
}