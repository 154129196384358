export function getUrlRoot(): string {
  if (typeof window === 'undefined') {
    return process.env.NEXT_PUBLIC_URL_ROOT as string
  }
  else {
    return `${document.location.origin}`
  }
}

export function getFullUrl(path: string): string {
  return `${getUrlRoot()}${path}`
}