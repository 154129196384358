import React from 'react'
import { createWebappImgixUrl } from '../utils/imgix'

interface ImageProps {
    imagePath: string,
    width: number,
    dpr: number,
    fit: "clip" | "crop",
    ar: string,
    quality: number,
    alt?: string
}

export default function Image({ imagePath, width, alt, fit, ar, dpr = 2, quality = 20 }: ImageProps) {
    return (
        <img
            src={createWebappImgixUrl(imagePath, {
                width,
                fit,
                aspectRatio: ar,
                dpr,
                quality
            })}
            style={{
                width: width
            }}
            alt={alt}
        />
    )
}


