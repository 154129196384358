import * as yup from 'yup'

export function validate<T>(schema: yup.SchemaOf<T>, data: T): [yup.ValidationError[], T] {
  const castedData = schema.cast(data)

  try {
    schema.validateSync(castedData, {
      strict: true,
      abortEarly: false,
    })
    return [[], castedData as T]
  } catch (e) {
    if (e instanceof yup.ValidationError) {
      return [e.inner || [e], castedData as T]
    }

    throw e
  }
}
