import classNames from 'classnames'
import styles from './Section.module.css'
import Spacing, { SPACINGS } from '../Spacing/Spacing'
import { AnyChildren } from '../types'
import { CSSProperties } from 'react'

export enum SECTION_THEMES {
  DARK = "dark",
  LIGHT = "light",
  COLOR = "color",
}

export enum JUSTIFICATIONS {
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center"
}

export enum VERTICAL_ALIGNS {
  TOP = "top",
  BOTTOM = "bottom",
  CENTER = "center"
}


export enum WIDTHS {
  SLIM = "slim",
  SLENDER = "slender",
  WIDE = "wide",
  WIDER = "wider",
  SUPERWIDE = "superwide",
  FULL = "full",
}

export enum HEIGHTS {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

interface SectionBaseProps {
  children: AnyChildren,
  justify?: JUSTIFICATIONS,
  width?: WIDTHS,
  height?: HEIGHTS,
  verticalAlign?: VERTICAL_ALIGNS,
  verticalSpacing?: SPACINGS,
  horizontalSpacing?: SPACINGS,
  topMargin?: SPACINGS,
  backgroundImageUrl?: string,
  style?: CSSProperties,
  innerHeroStyle?: CSSProperties,
  className?: string,
  hero?: boolean,
}

interface SectionProps extends SectionBaseProps {
  theme?: SECTION_THEMES,
}

export default function Section({
  justify,
  width,
  height,
  verticalAlign,
  verticalSpacing = SPACINGS.NONE,
  horizontalSpacing = SPACINGS.SMALL,
  topMargin,
  theme,
  backgroundImageUrl,
  style,
  innerHeroStyle,
  children,
  className: extraClassName,
  hero,
}: SectionProps) {


  const className = classNames(
    styles.Section,
    extraClassName,
    {
      [styles['--dark']]: theme === SECTION_THEMES.DARK,
      [styles['--light']]: theme === SECTION_THEMES.LIGHT,
      [styles['--color']]: theme === SECTION_THEMES.COLOR,
      [styles['--invertColor']]: theme && [
        SECTION_THEMES.DARK,
      ].includes(theme),
      [styles[`--justification-${justify}`]]: !!justify,
      [styles[`--width-${width}`]]: !!width,
      [styles[`--height-${height}`]]: !!height,
      [styles[`--verticalAlign-${verticalAlign}`]]: !!verticalAlign,
      [styles[`--topMargin-${topMargin}`]]: !!topMargin,
      [styles[`--withBackgroundImage`]]: !!backgroundImageUrl,
      [styles['--hero']]: hero,
    })

  return (
    <section className={className} style={{
      ...(backgroundImageUrl ? {
        backgroundImage: `url(${backgroundImageUrl})`
      } : null),
      ...style
    }}>
      {hero && (
        <div className={styles.Section_Hero}>
          <div className={styles.Section_Hero_Inner} style={innerHeroStyle} />
        </div>
      )}
      <div className={styles.Section_Inner}>
        <Spacing
          verticalSpacing={verticalSpacing}
          horizontalSpacing={horizontalSpacing}
        >
          {children}
        </Spacing>
      </div>
    </section>
  )
}


function sectionComponentFactory(theme: SECTION_THEMES) {
  return function SectionComponent(props: SectionBaseProps) {
    return (
      <Section theme={theme} {...props} />
    )
  }
}

export const DarkSection = sectionComponentFactory(SECTION_THEMES.DARK)
export const LightSection = sectionComponentFactory(SECTION_THEMES.LIGHT)
export const ColorSection = sectionComponentFactory(SECTION_THEMES.COLOR)