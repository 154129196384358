import { createContext, useState, useContext, useCallback, useMemo, useEffect } from 'react'
import { CourseProductOrderRow, orderRowSchema } from './useOrderRow'
import * as yup from 'yup'
import { validate } from '../utils/schemaValidation'
import useBooleanState from '../hooks/useBooleanState'
import CoursesApiClientContext from '../contexts/CoursesApiClientContext'
import { CoursesApiClient, CoursesApiRequestError, ID } from '../lib/coursesApi/CoursesApiClient'
import { requiredField, emailSchemaFactory, stringSchemaFactory, swedishOrganisationNumberSchemaFactory, swedishPostalCodeSchemaFactory } from '../forms/schemas'

export interface Company {
  id?: number,
  registrationNumber: string,
  name: string
}

export const companySchema = yup.object({
  registrationNumber: swedishOrganisationNumberSchemaFactory(true),
  name: stringSchemaFactory(true, 2)
}).defined()

export enum InvoiceMethods {
  Invoice = "invoice",
  EInvoice = "e-invoice"
}

export interface CommonInvoiceSettings {
  email: string,
  reference?: string,
}

export interface InvoiceSettings extends CommonInvoiceSettings {
  email: string,
  reference?: string,
  address: string,
  postalCode: string,
  city: string,
}

export interface EInvoiceSettings extends CommonInvoiceSettings {
}

export type InvoiceOrEInvoiceSettings = InvoiceSettings | EInvoiceSettings

export const invoiceSettingsSchema = yup.object({
  email: emailSchemaFactory(true),
  reference: stringSchemaFactory(),
  address: stringSchemaFactory(true, 4),
  postalCode: swedishPostalCodeSchemaFactory(true),
  city: stringSchemaFactory(true, 2),
}).defined()


export const eInvoiceSettingsSchema = yup.object({
  email: emailSchemaFactory(true),
  reference: stringSchemaFactory()
}).defined()

export interface Order {
  id?: string,
  lastAdminEmail?: string,
  orderRows: CourseProductOrderRow[],
  company: Company,
  invoiceMethod: InvoiceMethods,
  invoiceSettings: InvoiceOrEInvoiceSettings,
  hasApprovedTermsOfPurchase: boolean,
  isSubmitted: boolean
}

export const orderSchemaFactory = (order: Order) => {

  const baseOrderSchema = yup.object({
    id: yup.string(),
    lastAdminEmail: emailSchemaFactory(false),
    orderRows: yup.array().of(orderRowSchema).ensure().defined(),
    company: companySchema.defined(),
    invoiceMethod: requiredField(
      yup.mixed<InvoiceMethods>().oneOf(Object.values(InvoiceMethods))
    ),
  }).defined()

  switch (order.invoiceMethod) {
    case InvoiceMethods.Invoice:
      return yup.object({
        invoiceSettings: requiredField(invoiceSettingsSchema)
      }).concat(baseOrderSchema)
    case InvoiceMethods.EInvoice:
    default:
      return yup.object({
        invoiceSettings: requiredField(eInvoiceSettingsSchema)
      }).concat(baseOrderSchema)
  }
}

const emptyOrder: Order = {
  id: undefined,
  lastAdminEmail: "",
  hasApprovedTermsOfPurchase: false,
  orderRows: [],
  company: {
    registrationNumber: "",
    name: ""
  },
  invoiceMethod: InvoiceMethods.Invoice,
  invoiceSettings: {
    email: "",
    reference: "",
    address: "",
    postalCode: "",
    city: ""
  } as InvoiceSettings,
  isSubmitted: false
}

export interface SubmittedOrder {
  id: ID,
}

interface IOrderApi {
  setOrder: (nextOrderFn: (currentOrder: Order) => Order, shouldTriggerValidation?: boolean) => void,
  setCompany: (nextCompanyFn: (currentCompany: Company) => Company) => void,
  setInvoiceMethod: (nextInvoiceMethod: InvoiceMethods) => void,
  setInvoiceSettings: (nextInvoiceSettingsFn: (currentInvoiceSettings: InvoiceOrEInvoiceSettings) => InvoiceOrEInvoiceSettings) => void,
  setOrderRows: (nextOrderRowsFn: (currentOrderRows: CourseProductOrderRow[]) => CourseProductOrderRow[]) => void,
  addOrderRow: (orderRow: CourseProductOrderRow) => void,
  removeOrderRow: (orderRowIndex: number) => void,
  changeOrderRow: (orderRowIndex: number, orderRow: CourseProductOrderRow) => void,
  setApprovalOfTermsOfPurchase: (approve?: boolean) => void,
  triggerValidation: () => void,
  submit: () => Promise<(SubmittedOrder | undefined)>,
  reset: () => void,
}

const dummyOrderApi: IOrderApi = {
  setOrder: () => null,
  setCompany: () => null,
  setInvoiceMethod: () => null,
  setInvoiceSettings: () => null,
  setOrderRows: () => null,
  addOrderRow: () => null,
  removeOrderRow: () => null,
  changeOrderRow: () => null,
  triggerValidation: () => null,
  setApprovalOfTermsOfPurchase: () => null,
  submit: async () => undefined,
  reset: () => null,
}

type IOrderStateContextValue = [yup.ValidationError[], Order, boolean]
export const OrderStateContext = createContext<IOrderStateContextValue>([[], emptyOrder, false])
export const OrderApiContext = createContext<IOrderApi>(dummyOrderApi)

interface OrderContextProviderProps {
  children: JSX.Element,
}

const LOCALSTORAGE_KEY = "__ORDER__"

function getLocallyStoredOrder(): (Order | undefined) {
  const orderStrFromLocalStorage = sessionStorage.getItem(LOCALSTORAGE_KEY)

  if (orderStrFromLocalStorage) {
    return JSON.parse(orderStrFromLocalStorage) as Order
  }

  return undefined
}

function storeLocalOrder(order: Order) {
  sessionStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(order))
}

export enum CompanyTypes {
  Company = "company",
  Government = "government",
}

export const defaultInvoiceMethodForCompanyTypeMap = {
  [CompanyTypes.Company]: InvoiceMethods.Invoice,
  [CompanyTypes.Government]: InvoiceMethods.EInvoice,
}

export function getCompanyType(company: Company): CompanyTypes {
  const firsLetterOfRegistrationNumber = company.registrationNumber[0]

  switch (firsLetterOfRegistrationNumber) {
    case '2':
      return CompanyTypes.Government
    default:
      return CompanyTypes.Company
  }
}

export function OrderContextProvider({ children }: OrderContextProviderProps) {

  // const [order, setOrder] = useState<Order>(emptyOrder)
  // const [validationErrors, setValidationErrors] = useState<yup.ValidationError[]>([])

  const [state, setState] = useState<[yup.ValidationError[], Order]>(() => [[], emptyOrder])
  const [validationErrors, order] = state
  const [hasTriggeredSubmit, triggerSubmit, resetTriggerSubmit] = useBooleanState(false)

  const setOrder = useCallback((nextOrderFn: (currentOrder: Order) => Order) => {
    setState(([currentValidationErrors, currentOrder]) => [
      currentValidationErrors,
      nextOrderFn(currentOrder)
    ])
  }, [setState])

  const setValidationErrors = useCallback((nextValidationErrorsFn: (currentValidationErrors: yup.ValidationError[]) => yup.ValidationError[]) => {
    setState(([currentValidationErrors, currentOrder]) => [
      nextValidationErrorsFn(currentValidationErrors),
      currentOrder,
    ])
  }, [setState])

  const [isSubmitting, setIsSubmitting, setIsNotSubmitting] = useBooleanState(false)

  useEffect(() => {
    const storedOrder = getLocallyStoredOrder()
    if (storedOrder) {
      setOrder(() => storedOrder)
    }
  }, [setOrder])

  useEffect(() => {
    storeLocalOrder(order)
  }, [order])

  const setCompany = useCallback<IOrderApi['setCompany']>((nextCompanyFn) => {
    setOrder((currentOrder) => {
      const nextCompany = nextCompanyFn(currentOrder.company)

      return {
        ...currentOrder,
        company: nextCompany
      }
    })
  }, [setOrder])

  const setInvoiceMethod = useCallback<IOrderApi['setInvoiceMethod']>((nextInvoiceMethod) => {
    setOrder((currentOrder) => {
      return {
        ...currentOrder,
        invoiceMethod: nextInvoiceMethod
      }
    })
  }, [setOrder])

  const setInvoiceSettings = useCallback<IOrderApi['setInvoiceSettings']>((nextInvoiceSettingsFn) => {
    setOrder((currentOrder) => {
      const nextInvoiceSettings = nextInvoiceSettingsFn(currentOrder.invoiceSettings)

      return {
        ...currentOrder,
        invoiceSettings: nextInvoiceSettings
      }
    })
  }, [setOrder])

  const setOrderRows = useCallback<IOrderApi['setOrderRows']>((nextOrderRowsFn) => {
    setOrder((currentOrder) => {
      const nextOrderRows = nextOrderRowsFn(currentOrder.orderRows)
      return {
        ...currentOrder,
        orderRows: nextOrderRows,
        lastAdminEmail: nextOrderRows.length > 0 ? nextOrderRows[nextOrderRows.length - 1].adminEmail : emptyOrder.lastAdminEmail
      }
    })
  }, [setOrder])

  const addOrderRow = useCallback<IOrderApi['addOrderRow']>((orderRow) => {
    setOrderRows((currentOrderRows) => [
      ...currentOrderRows,
      orderRow
    ])
  }, [setOrderRows])

  const removeOrderRow = useCallback<IOrderApi['removeOrderRow']>((orderRowIndexToBeRemoved) => (
    setOrderRows((currentOrderRows) => currentOrderRows.filter((orderRow, orderRowIndex) => orderRowIndex !== orderRowIndexToBeRemoved))
  ), [setOrderRows])

  const changeOrderRow = useCallback<IOrderApi['changeOrderRow']>((orderRowIndexToBeChanged, orderRow) => {
    setOrderRows((currentOrderRows) => currentOrderRows.map((currentOrderRow, orderRowIndex) => (
      orderRowIndex === orderRowIndexToBeChanged ? orderRow : currentOrderRow
    )))
  }, [setOrderRows])

  const setApprovalOfTermsOfPurchase = useCallback<IOrderApi['setApprovalOfTermsOfPurchase']>((approve) => {
    setOrder((currentOrder) => ({
      ...currentOrder,
      hasApprovedTermsOfPurchase: approve === undefined ? !currentOrder.hasApprovedTermsOfPurchase : approve,
    }))
  }, [setOrder])

  const triggerValidation = useCallback(() => {
    setState(([, currentOrder]) => {
      const [nextValidationErrors, nextOrder] = validate<Order>(
        orderSchemaFactory(currentOrder) as unknown as yup.SchemaOf<Order>,
        currentOrder
      )

      return [
        nextValidationErrors,
        nextOrder
      ]
    })
  }, [setState])

  const coursesApiClient = useContext(CoursesApiClientContext) as CoursesApiClient

  const submit = useCallback<IOrderApi['submit']>(async () => {
    triggerSubmit()

    if (validationErrors.length === 0) {
      setIsSubmitting()

      let createdOrder: (SubmittedOrder | undefined) = undefined;
      setOrder((currentOrder) => ({
        ...currentOrder,
        isSubmitted: true
      }))

      try {
        createdOrder = (await coursesApiClient.submitCourseParticipationOrder(
          order.company,
          order.invoiceSettings as InvoiceSettings,
          order.orderRows
        )) as any as SubmittedOrder



      } catch (error) {

        switch (error instanceof CoursesApiRequestError ? error.payload.error_code : undefined) {
          case 'invalid-registration-number':
            setValidationErrors((currentValidationErrors) => [
              ...currentValidationErrors,
              new yup.ValidationError("Felaktigt organisationsnummer", order, "")
            ])
            break
          default:
            setValidationErrors((currentValidationErrors) => [
              ...currentValidationErrors,
              new yup.ValidationError("Okänt fel", order, "")
            ])
            break
        }


      }

      setIsNotSubmitting()

      return createdOrder
    }

  }, [order, setIsSubmitting, setIsNotSubmitting, coursesApiClient, order, setValidationErrors, validationErrors, triggerSubmit])

  const reset = useCallback<IOrderApi['reset']>(() => {
    setState(() => [[], emptyOrder])
    resetTriggerSubmit()
  }, [setState, resetTriggerSubmit])

  const apiContextValue = useMemo<IOrderApi>(() => ({
    setOrder,
    setCompany,
    setInvoiceMethod,
    setInvoiceSettings,
    setOrderRows,
    addOrderRow,
    removeOrderRow,
    changeOrderRow,
    triggerValidation,
    setApprovalOfTermsOfPurchase,
    submit,
    reset
  }), [setOrder, setCompany, setInvoiceMethod, setInvoiceSettings, setOrderRows, addOrderRow, removeOrderRow, changeOrderRow, triggerValidation, submit, reset])

  const stateContextValue = useMemo<IOrderStateContextValue>(() => ([
    hasTriggeredSubmit ? validationErrors : [], order, isSubmitting
  ]), [hasTriggeredSubmit, validationErrors, order, isSubmitting])

  return (
    <OrderStateContext.Provider value={stateContextValue}>
      <OrderApiContext.Provider value={apiContextValue}>
        {children}
      </OrderApiContext.Provider>
    </OrderStateContext.Provider>
  )
}


export function useOrder() {
  const order = useContext(OrderStateContext)
  return order
}

export function useOrderApi() {
  const orderApi = useContext(OrderApiContext)
  return orderApi
}