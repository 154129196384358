import qs from 'qs'

type fit = "crop" | "clip"

export interface ImgixUrlSettings {
  width?: number,
  height?: number,
  fit?: fit,
  aspectRatio?: string,
  dpr?: number,
  quality?: number,
  autoCompress?: boolean,
  autoFormat?: boolean
}

const defaultImgixUrlSettings: ImgixUrlSettings = {
  dpr: 1,
  quality: 20,
  autoCompress: true,
  autoFormat: true
}

export function createImgixUrl(imgixDomain: string, imagePath: string, settings: ImgixUrlSettings = {}): string {
  const mergedSettings: ImgixUrlSettings = {
    ...defaultImgixUrlSettings,
    ...settings
  }

  const queryString = qs.stringify({
    w: mergedSettings.width,
    h: mergedSettings.height,
    dpr: mergedSettings.dpr,
    q: mergedSettings.quality,
    ar: mergedSettings.aspectRatio,
    fit: mergedSettings.fit,
    auto: [
      ...(mergedSettings.autoCompress ? ["compress"] : []),
      ...(mergedSettings.autoFormat ? ["format"] : []),
    ].join(",")
  })

  return `${imgixDomain}${imagePath}?${queryString}`
}

export function createWebappImgixUrl(imagePath: string, settings?: ImgixUrlSettings) {
  return createImgixUrl(
    'https://ea-redwine-webapp-images.imgix.net',
    imagePath, settings
  )
}