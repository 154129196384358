import classNames from 'classnames'
import styles from './Spacing.module.css'
import { AnyChildren } from '../types'

export enum SPACINGS {
  NONE = "none",
  TINY = "tiny",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  XLARGE = "xlarge",
  MEGA = "mega"
}

export interface SpacingProps {
  verticalSpacing?: SPACINGS,
  horizontalSpacing?: SPACINGS,
  children: AnyChildren
}

export default function Spacing({ verticalSpacing, horizontalSpacing, children }: SpacingProps) {
  const className = classNames(styles.Spacing, {
    [styles[`--verticalSpacing-${verticalSpacing}`]]: !!verticalSpacing,
    [styles[`--horizontalSpacing-${horizontalSpacing}`]]: !!horizontalSpacing,
  })

  return (
    <div className={className}>
      {children}
    </div>
  )
}