import React, { Fragment, PropsWithChildren } from 'react'
import { MDXProvider, MDXProviderComponentsProp } from '@mdx-js/react'

import { Button, Divider, Typography } from 'antd'
import { ButtonProps } from 'antd/lib/button'

import Link from '../components/Link'
import Section, { DarkSection, LightSection, ColorSection } from '../components/Section/Section'
import LinkButton, { LinkButtonProps } from '../components/LinkButton/LinkButton'
import Spacing from '../components/Spacing/Spacing'
import ImgixImage from '../components/Image'
import PreTitle from '../components/PreTitle/PreTitle'


const mdxComponents: MDXProviderComponentsProp = {
  h1: (props) => <Typography.Title level={1} {...props} />,
  h2: (props) => <Typography.Title level={2} {...props} />,
  h3: (props) => <Typography.Title level={3} {...props} />,
  h4: (props) => <Typography.Title level={4} {...props} />,
  h5: (props) => <Typography.Title level={5} {...props} />,
  p: (props) => <Typography.Paragraph {...props} />,
  a: Link,
  Link,
  PreTitle,
  Title: Typography.Title,
  Paragraph: Typography.Paragraph,
  hr: Divider,
  Fragment: Fragment,
  Image: ImgixImage,
  Button,
  PrimaryButton: (props: ButtonProps) => <Button {...props} type="primary" />,
  LargePrimaryButton: (props: ButtonProps) => <Button {...props} type="primary" size="large" />,
  LinkButton,
  PrimaryLinkButton: ({ buttonProps, ...props }: LinkButtonProps) => <LinkButton {...props} buttonProps={{ type: "primary", ...buttonProps }} />,
  LargePrimaryLinkButton: ({ buttonProps, ...props }: LinkButtonProps) => <LinkButton {...props} buttonProps={{ type: "primary", size: "large", ...buttonProps }} />,
  RoundedButton: (props: ButtonProps) => <Button {...props} shape="round" />,
  Section,
  DarkSection,
  LightSection,
  ColorSection,
  Spacing,
}

export default function DefaultMDXProvider({ children }: PropsWithChildren<{}>) {
  return (
    <MDXProvider components={mdxComponents}>
      {children}
    </MDXProvider>
  )
}