import React, { useMemo, useState, useCallback, useEffect, useContext } from 'react'
import { CourseParticipation, ID, CoursesApiClient, UserSettings, CompanyCourseParticipationTicketBundleAdministrationSummary } from '../lib/coursesApi/CoursesApiClient'
import useLoadableState, { LoadableBundle, LoadableApi, dummyLoadableApi, emptyLoadableBundleFactory } from '../hooks/useLoadableState'
import CoursesApiClientContext from '../contexts/CoursesApiClientContext'

export enum AuthenticationType {
  BankID = "bankid",
  SMS = "sms",
  BasicAuth = "basic_auth"
}

export interface UserProfile {
  family_name: string,
  given_name: string,
  locale: string,
  name: string,
  nickname: string,
  picture: string,
  sub: string,
  updated_at: string,
  jti: string,
  exp: number,
  iat: number,
  email: string,
  phone_number: string,
  authentication_type: AuthenticationType
}

export enum AuthenticationState {
  Pending = "pending",
  Authenticated = "authenticated",
  NotAuthenticated = "not-authenticated"
}

export interface UserStateValue {
  authenticationState: AuthenticationState,
  isAuthenticated: boolean,
  hasPendingAuthentication: boolean,
  profileBundle: LoadableBundle<UserProfile>,
  courseParticipationsBundle: LoadableBundle<CourseParticipation[]>,
  userSettingsBundle: LoadableBundle<UserSettings>,
  companyCourseParticipationTicketBundleAdministrationSummaryBundle: LoadableBundle<CompanyCourseParticipationTicketBundleAdministrationSummary>
}


const initialState: UserStateValue = {
  authenticationState: AuthenticationState.Pending,
  hasPendingAuthentication: true,
  isAuthenticated: false,
  profileBundle: emptyLoadableBundleFactory<UserProfile>(),
  courseParticipationsBundle: emptyLoadableBundleFactory<CourseParticipation[]>(),
  userSettingsBundle: emptyLoadableBundleFactory<UserSettings>(),
  companyCourseParticipationTicketBundleAdministrationSummaryBundle: emptyLoadableBundleFactory<CompanyCourseParticipationTicketBundleAdministrationSummary>(),
}

export const UserStateContext = React.createContext<UserStateValue>(initialState)

interface IUserApiContext {
  courseParticipations: LoadableApi<CourseParticipation[]>,
  settings: LoadableApi<UserSettings>,
  refresh: () => void,
  refreshCourseParticipations: () => void,
  refreshToken: () => void,
}

export const UserApiContext = React.createContext<IUserApiContext>({
  courseParticipations: dummyLoadableApi,
  settings: dummyLoadableApi,
  refresh: () => null,
  refreshToken: () => null,
  refreshCourseParticipations: () => null
})

interface UserProviderProps {
  children: JSX.Element
}

export function UserProvider({ children }: UserProviderProps) {

  const [authenticationState, setAuthenticationState] = useState(AuthenticationState.Pending)
  const isAuthenticated = authenticationState === AuthenticationState.Authenticated
  const hasPendingAuthentication = authenticationState === AuthenticationState.Pending

  const [profileBundle, profileBundleApi] = useLoadableState<UserProfile>()
  const [courseParticipationsBundle, courseParticipationsBundleApi] = useLoadableState<CourseParticipation[]>()
  const [userSettingsBundle, userSettingsBundleApi] = useLoadableState<UserSettings>()

  const [companyCourseParticipationTicketBundleAdministrationSummaryBundle, companyCourseParticipationTicketBundleAdministrationSummaryBundleApi] = useLoadableState<CompanyCourseParticipationTicketBundleAdministrationSummary>()

  const coursesApiClient = useContext(CoursesApiClientContext) as CoursesApiClient

  const fetchUser = useCallback(async function () {
    profileBundleApi.setIsLoading(true)

    try {
      const response = await fetch('/api/auth/me')

      if (response.ok) {
        const nextUserProfile = await response.json()
        profileBundleApi.setLoadedValueWithoutError(nextUserProfile)
        setAuthenticationState(AuthenticationState.Authenticated)
      }
      else {
        setAuthenticationState(AuthenticationState.NotAuthenticated)
        const errorPayload = await response.json()
        profileBundleApi.setError(new Error(JSON.stringify(errorPayload)))
      }
    } catch (error) {
      if (error instanceof Error) {
        profileBundleApi.setError(error)
      }
      profileBundleApi.setIsLoading(false)
    }
  }, [profileBundleApi, setAuthenticationState])

  useEffect(() => {
    let stillMounted = true

    fetchUser()

    return function cleanup() {
      stillMounted = false
    }
  }, [setAuthenticationState])

  const refreshUserSettings = useCallback(async function () {
    userSettingsBundleApi.setIsLoading(true)

    try {
      const nextUserSettings = await coursesApiClient.getUserSettings()
      userSettingsBundleApi.setLoadedValueWithoutError(nextUserSettings)
    } catch (error) {
      if (error instanceof Error) {
        userSettingsBundleApi.setError(error)
      }
    }

    userSettingsBundleApi.setIsLoading(false)
  }, [coursesApiClient, userSettingsBundleApi])

  const refreshCourseParticipations = useCallback(async function () {
    courseParticipationsBundleApi.setIsLoading(true)

    try {
      const nextCourseParticipations = await coursesApiClient.getUserCourseParticipations()
      courseParticipationsBundleApi.setLoadedValueWithoutError(nextCourseParticipations)
    } catch (error) {
      if (error instanceof Error) {
        courseParticipationsBundleApi.setError(error)
      }
    }

    courseParticipationsBundleApi.setIsLoading(false)
  }, [coursesApiClient, courseParticipationsBundleApi])

  const refreshCompanyCourseParticipationTicketBundleAdministrationSummaryBundle = useCallback(async function () {
    companyCourseParticipationTicketBundleAdministrationSummaryBundleApi.setIsLoading(true)

    try {
      const nextUserIsAdminForCompanies = await coursesApiClient.getCompanyCourseParticipationTicketBundlesSummary()
      companyCourseParticipationTicketBundleAdministrationSummaryBundleApi.setLoadedValueWithoutError(nextUserIsAdminForCompanies)
    } catch (error) {
      if (error instanceof Error) {
        companyCourseParticipationTicketBundleAdministrationSummaryBundleApi.setError(error)
      }
    }

    companyCourseParticipationTicketBundleAdministrationSummaryBundleApi.setIsLoading(false)
  }, [coursesApiClient, companyCourseParticipationTicketBundleAdministrationSummaryBundleApi])

  const refreshUser = useCallback(() => {
    refreshUserSettings()
    refreshCourseParticipations()
    refreshCompanyCourseParticipationTicketBundleAdministrationSummaryBundle()
  }, [
    refreshUserSettings,
    refreshCourseParticipations,
    refreshCompanyCourseParticipationTicketBundleAdministrationSummaryBundle,
  ])

  useEffect(() => {
    if (isAuthenticated) {
      refreshUser()
      return function cleanup() { }
    }
  }, [isAuthenticated])

  const refreshToken = useCallback(async function () {
    try {
      const response = await fetch('/api/auth/refresh-token')
      if (response.ok) {
        await fetchUser()
        await refreshUser()
      }
    } catch (error) {

    }
  }, [fetchUser, refreshUser])

  const stateContextValue = useMemo<UserStateValue>(() => ({
    authenticationState,
    isAuthenticated,
    hasPendingAuthentication,
    profileBundle,
    courseParticipationsBundle,
    userSettingsBundle,
    companyCourseParticipationTicketBundleAdministrationSummaryBundle,
  }), [
    authenticationState, isAuthenticated, hasPendingAuthentication,
    profileBundle,
    courseParticipationsBundle,
    userSettingsBundle,
    companyCourseParticipationTicketBundleAdministrationSummaryBundle
  ])

  return (
    <UserStateContext.Provider value={stateContextValue}>
      <UserApiContext.Provider value={{
        courseParticipations: courseParticipationsBundleApi,
        settings: userSettingsBundleApi,
        refresh: refreshUser,
        refreshToken,
        refreshCourseParticipations
      }}>
        {children}
      </UserApiContext.Provider>
    </UserStateContext.Provider>
  )
}

export function useUserState() {
  return useContext(UserStateContext)
}

export function useUserApi() {
  return useContext(UserApiContext)
}

export function useUserCourseParticipationsState() {
  const { courseParticipationsBundle } = useUserState()
  return courseParticipationsBundle
}

export function useUserProfile() {
  const { profileBundle } = useUserState()
  return profileBundle[2]
}

export function useUserAuthenticationType() {
  const profile = useUserProfile()
  return profile?.authentication_type
}

export function useUserCourseParticipationsApi() {
  const { courseParticipations: { setValueCallback } } = useUserApi()

  const api = useMemo(() => ({
    setCourseParticipation(courseParticipationId: ID, nextCourseParticipation: CourseParticipation) {
      setValueCallback((courseParticipations) =>
        (courseParticipations as CourseParticipation[]).map((courseParticipation) =>
          courseParticipation.id === courseParticipationId ?
            nextCourseParticipation :
            courseParticipation
        )
      )
    },
    addCourseParticipation(nextCourseParticipation: CourseParticipation) {
      setValueCallback((currentCourseParticipations) => [
        ...(currentCourseParticipations || []),
        nextCourseParticipation
      ])
    }
  }), [setValueCallback])

  return api
}

export function useUserCourseParticipationState(courseParticipationId: ID): LoadableBundle<CourseParticipation> {
  const [isLoading, error, courseParticipations] = useUserCourseParticipationsState()

  const courseParticipation = useMemo(() => {
    if (courseParticipations) {
      const foundCourseParticipation = courseParticipations.find((courseParticipation) => courseParticipation.id === courseParticipationId)

      if (!foundCourseParticipation) {
        throw new Error("Course Participation ID missmatch")
      }

      return foundCourseParticipation
    }

    return undefined
  }, [courseParticipations, courseParticipationId])

  return [isLoading, error, courseParticipation]
}

export function useUserCourseParticipationApi(courseParticipationId: ID) {
  const { setCourseParticipation: replaceCourseParticipation } = useUserCourseParticipationsApi()
  const coursesApiClient = useContext(CoursesApiClientContext)

  const setCourseParticipation = useCallback((nextCourseParticipation) => {
    replaceCourseParticipation(
      courseParticipationId,
      nextCourseParticipation
    )
  }, [replaceCourseParticipation, courseParticipationId])

  const addViewedLesson = useCallback(async function (lessonId: ID) {
    const nextCourseParticipation = await coursesApiClient.addViewedCourseParticipationLesson(
      courseParticipationId,
      lessonId
    )

    setCourseParticipation(
      nextCourseParticipation
    )
  }, [coursesApiClient, setCourseParticipation, courseParticipationId])

  const api = {
    setCourseParticipation,
    addViewedLesson,
  }

  return api
}