import { useState, useCallback, useMemo } from 'react'

type UseBooleanStateMethod = () => void
type IUseBooleanState = [boolean, UseBooleanStateMethod, UseBooleanStateMethod, UseBooleanStateMethod]

export default function useBooleanState(initialState: boolean): IUseBooleanState {
  const [state, setState] = useState<boolean>(initialState)
  const toTrue = useCallback<UseBooleanStateMethod>(() => setState(true), [setState])
  const toFalse = useCallback<UseBooleanStateMethod>(() => setState(false), [setState])
  const toggle = useCallback<UseBooleanStateMethod>(() => setState((currentState) => !currentState), [setState])

  return [
    state,
    toTrue, toFalse,
    toggle
  ]
}