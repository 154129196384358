import * as yup from 'yup'


export function requiredField<S extends yup.AnySchema>(schema: S): S {
  return schema.required("Obligatoriskt fält")
}

function maybeRequiredField<S extends yup.AnySchema>(schema: S, required?: boolean): S {
  return required ? requiredField(schema) : schema
}

export const emailSchemaFactory = (required?: boolean) => maybeRequiredField(yup.string().trim(), required).email("Ogiltig epost-adress")

export const mobileSchemaFactory = (required?: boolean) => maybeRequiredField(yup.string().trim(), required)
  .transform((currentMobile: string) => {
    return currentMobile.replace(/^00/, "0").replace(/^0/, "+46").replace(/[\s\-]/g, "")
  })
  .matches(/^\+46\d{3}\-?\d{6}/, "Ogiltigt format")


export const stringSchemaFactory = (required?: boolean, minLength?: number) => {
  const baseSchema = maybeRequiredField(yup.string().trim(), required)

  if (minLength) {
    return baseSchema.min(minLength, `Minst ${minLength} antal tecken`)
  }

  return baseSchema
}

export const swedishOrganisationNumberSchemaFactory = (required?: boolean) => maybeRequiredField(yup.string().trim(), required)
  .transform((value) => value.replace(/\s/g, ""))
  .transform((value) => value.replace(/^(\d{6})(\-?)(\d{4})$/, "$1$3"))
  .matches(/^\d{6}\d{4}$/, "Felaktigt format")


export const swedishPostalCodeSchemaFactory = (required?: boolean) => maybeRequiredField(yup.string().trim(), required)
  .transform((value) => value.replace(/\s/g, ""))
  .matches(/^\d{5}$/, "Ogiltigt format")