import qs from 'qs'

export type RequestPath = string | { path: string, query?: { [key: string]: string[] | string | undefined } }

export function requestPathToString(requestPath: RequestPath): string {
  const pathString = (function () {
    if (typeof requestPath === 'string') {
      return requestPath
    }

    else {
      return `${requestPath.path}${(function () {
        if (requestPath.query) {
          return `?${qs.stringify(requestPath.query, {
            arrayFormat: "repeat"
          })}`
        }

        return ""
      })()}`
    }
  })()

  return pathString
}