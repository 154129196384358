import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { useCallback, AnchorHTMLAttributes, forwardRef, CSSProperties } from "react";

interface InnerLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  onCustomClick?: (e: MouseEvent) => void
}

const InnerLink = forwardRef<HTMLAnchorElement, InnerLinkProps>(function InnerLink({ onClick, onCustomClick, children, ...aProps }: InnerLinkProps, ref) {
  const handleClick = useCallback((e) => {
    if (onCustomClick) {
      onCustomClick(e)
    }
    if (onClick) {
      onClick(e)
    }
  }, [onClick, onCustomClick])

  return (
    <a
      {...aProps}
      onClick={handleClick}
      ref={ref}
    >
      {children}
    </a>
  )
})

export interface LinkProps extends React.PropsWithChildren<NextLinkProps> {
  onClick?: (e: MouseEvent) => void,
  className?: string,
  style?: CSSProperties,
  native?: boolean,
  external?: boolean,
}


export default function Link({ children, onClick, className, style, native, external, ...NextLinkProps }: LinkProps) {
  return (
    native ?
      <a
        href={NextLinkProps.href.toString()}
        {...native ? {
          rel: "noopener",
          target: "_blank"
        } : null}
      >{children}</a> :
      <NextLink {...NextLinkProps} passHref>
        <InnerLink
          onCustomClick={onClick}
          style={style}
          className={className}
        >{children}</InnerLink>
      </NextLink>
  )
}